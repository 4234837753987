// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import opswatLogo from "assets/images/opswat_logo.png";
import katalonLogo from "assets/images/katalon_logo.png";
import gridlyLogo from "assets/images/gridly_logo.png";
import booksalonLogo from "assets/images/bns_logo.png";
import melonmediaLogo from "assets/images/melonmedia_logo.png";
import jiratrackerLogo from "assets/images/streamlit_logo.png";

function Products() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      id="products"
    >
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <MKTypography variant="h3" mb={6} color="white">
            Products
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={opswatLogo}
                link="https://www.opswat.com/"
                name="OPSWAT"
                position={{ color: "info", label: "Cybersecurity Platform" }}
                description="A cybersecurity platform that gives public and private sector organizations the critical advantage in protecting."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={katalonLogo}
                link="https://katalon.com/"
                name="Katalon"
                position={{ color: "info", label: "Platform as a Service" }}
                description="An AI-augmented platform that lets you plan, author and execute automated tests."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={gridlyLogo}
                link="https://www.gridly.com/"
                name="Gridly"
                position={{ color: "info", label: "Software as a Service" }}
                description="A SaaS product of Localize Direct AB that offers solutions in the field of localization and content management."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={booksalonLogo}
                link="https://booksalon.ca/"
                name="BookSalon"
                position={{ color: "info", label: "Software as a Service" }}
                description="A startup in Canada with the mission to help Salons optimize their work processes and take care of customers."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={melonmediaLogo}
                link="https://melon-media.net/"
                name="Melon Media"
                position={{ color: "info", label: "Landing page" }}
                description="My solo project for Melon Media - an Influencer Marketing Agency that developed by NextJS and use Gridly as CMS."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={jiratrackerLogo}
                link="https://jira-tracker.streamlit.app/"
                name="Jira Tracker"
                position={{ color: "info", label: "Solo project" }}
                description="A web application that allows the development team tracks their performance based on the data from Jira."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Products;
