// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples

function BlogDetails() {
  const [data, setData] = useState([]);

  // @refresh reset
  // Handle slug URL
  const router = useLocation();
  const { pathname } = router;

  const getData = () => {
    const id = pathname.match("(?<=story/).*?(?=/)").toString();
    const fetchData = async () => {
      const source = await axios(
        `https://api.airtable.com/v0/appG5dDWzeqR98l4J/Post%20Details/${id}`,
        {
          headers: {
            Authorization:
              "Bearer patSTbBHsfUcIXjIO.eb40c4520ee080b37ae5ef41858fe4abfadb68f5e01d43b7cd9c0b5632d1a2c6",
          },
        }
      );
      const result = [];
      result.push(source.data);
      setData(result);
    };
    fetchData();
  };

  // Call API & handle search function
  useEffect(() => {
    getData();
  }, [pathname]);
  return (
    <MKBox component="section" position="relative" px={{ xs: 2, lg: 28 }} py={{ xs: 3, lg: 8 }}>
      <Container>
        <Grid container spacing={0} alignItems="center">
          {data.map((item) => (
            <div>
              <MKTypography variant="h1" sx={{ display: { xs: "none", xl: "block" } }}>
                {item.fields.Title}
              </MKTypography>
              <MKBox
                position="relative"
                borderRadius="lg"
                py={5}
                sx={{ display: { xs: "none", xl: "block" } }}
              >
                <MKBox
                  component="img"
                  src={item.fields.Thumbnail[0].url}
                  alt={item.fields.Title}
                  borderRadius="lg"
                  width="100%"
                  position="relative"
                  zIndex={1}
                />
                <MKBox
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  position="absolute"
                  left={0}
                  top={0}
                  sx={{
                    backgroundImage: `url(${item.fields.Thumbnail[0].url})`,
                    transform: "scale(0.94)",
                    filter: "blur(12px)",
                    backgroundSize: "cover",
                  }}
                />
              </MKBox>
              <MKTypography
                variant="body1"
                fontWeight="light"
                color="text"
                align="justify"
                component="div"
                sx={{ display: { xs: "none", xl: "block" } }}
              >
                <ReactMarkdown>{item.fields.Content}</ReactMarkdown>
              </MKTypography>
              {/* Responsive */}
              <MKTypography
                variant="h4"
                justifyContent="center"
                alignItems="center"
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                {item.fields.Title}
              </MKTypography>
              <MKBox
                position="relative"
                borderRadius="lg"
                py={2}
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                <MKBox
                  component="img"
                  src={item.fields.Thumbnail[0].url}
                  alt={item.fields.Title}
                  borderRadius="lg"
                  width="100%"
                  position="relative"
                  zIndex={1}
                />
                <MKBox
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  position="absolute"
                  left={0}
                  top={0}
                  sx={{
                    backgroundImage: `url(${item.fields.Thumbnail[0].url})`,
                    transform: "scale(0.94)",
                    filter: "blur(12px)",
                    backgroundSize: "cover",
                  }}
                />
              </MKBox>
              <MKTypography
                variant="body2"
                fontWeight="light"
                color="text"
                align="justify"
                component="div"
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                <ReactMarkdown>{item.fields.Content}</ReactMarkdown>
              </MKTypography>
            </div>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BlogDetails;
