// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/win_avt.png";

function Profile() {
  const date = new Date();
  const hours = date.getHours();

  let message;

  if (hours < 12) {
    message = "Good Morning!";
  } else if (hours < 18) {
    message = "Good Afternoon!";
  } else {
    message = "Good Evening!";
  }
  return (
    <MKBox component="section" pt={{ xs: 6, sm: 12 }} pb={0}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -24, sm: -30 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Win Le" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <MKTypography variant="h3" sx={{ display: { xs: "none", xl: "block" } }}>
                  {message}
                </MKTypography>
                <MKTypography variant="h5" sx={{ display: { xs: "block", xl: "none" } }}>
                  {message}
                </MKTypography>
                <MKButton variant="outlined" color="info" size="small" href="#contact">
                  Contact me
                </MKButton>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text" align="justify">
                As a proficient Product Manager, I bring to the table three years of significant
                experience in the SaaS realm, underpinned by a solid foundation in product
                management and development, as well as problem-solving. My proficiency lies in
                harnessing the power of data, thriving in dynamic environments, and swiftly adapting
                to unforeseen circumstances. These skills enable me to lead teams effectively,
                surmounting challenges with ease and confidence. <br />
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
