// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Information() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      pt={0}
      pb={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      id="skills"
    >
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={6} md={3}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    iconBaseClassName="fas"
                    iconClassName="fa-tasks"
                    title="Product management"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    iconBaseClassName="fas"
                    iconClassName="fa-sync-alt"
                    title="Agile methodologies"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3} sx={{ display: { xs: "none", md: "block" } }}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    iconBaseClassName="fas"
                    iconClassName="fa-lightbulb"
                    title="Problem-solving skills"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3} sx={{ display: { xs: "none", md: "block" } }}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    iconBaseClassName="fas"
                    iconClassName="fa-project-diagram"
                    title="Analytical skills"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    iconBaseClassName="fas"
                    iconClassName="fa-search"
                    title="UX research"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    iconBaseClassName="fas"
                    iconClassName="fa-chart-line"
                    title="Data analysis"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    iconBaseClassName="fab"
                    iconClassName="fa-aws"
                    title="Amazon Web Services"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    iconBaseClassName="fab"
                    iconClassName="fa-jira"
                    title="Jira"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3} sx={{ display: { xs: "none", md: "block" } }}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    iconBaseClassName="fab"
                    iconClassName="fa-figma"
                    title="Figma"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    iconBaseClassName="fab"
                    iconClassName="fa-python"
                    title="Python"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3} sx={{ display: { xs: "none", md: "block" } }}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    iconBaseClassName="fab"
                    iconClassName="fa-js"
                    title="Javascript"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={6} md={3}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    iconBaseClassName="fas"
                    iconClassName="fa-database"
                    title="SQL"
                    direction="center"
                    description=""
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              title="Get insights on Search"
              description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
