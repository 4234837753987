// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultInfoCard({
  color,
  iconBaseClassName,
  iconClassName,
  title,
  description,
  direction,
  small,
}) {
  return (
    <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}>
      {typeof iconClassName === "string" ? (
        <MKTypography
          display="block"
          variant={direction === "center" ? "h2" : "h3"}
          color={color}
          textGradient
        >
          <Icon
            style={{ width: "45px" }}
            baseClassName={iconBaseClassName}
            className={iconClassName}
          />
        </MKTypography>
      ) : (
        iconClassName
      )}
      <MKTypography
        variant="h5"
        fontWeight="bold"
        mt={direction === "center" ? 1 : 2}
        mb={1.5}
        sx={{ display: { xs: "none", xl: "block" } }}
      >
        {title}
      </MKTypography>
      <MKTypography
        variant="h6"
        fontWeight="bold"
        mt={direction === "center" ? 1 : 2}
        mb={1.5}
        sx={{ display: { xs: "block", xl: "none" } }}
      >
        {title}
      </MKTypography>
      <MKTypography
        display="block"
        variant={small ? "button" : "body2"}
        color="text"
        pr={direction === "left" ? 6 : 0}
        pl={direction === "right" ? 6 : 0}
      >
        {description}
      </MKTypography>
    </MKBox>
  );
}

// Setting default props for the DefaultInfoCard
DefaultInfoCard.defaultProps = {
  color: "info",
  direction: "left",
  small: false,
};

// Typechecking props for the DefaultInfoCard
DefaultInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  iconClassName: PropTypes.string.isRequired,
  iconBaseClassName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  direction: PropTypes.oneOf(["left", "right", "center"]),
  small: PropTypes.bool,
};

export default DefaultInfoCard;
