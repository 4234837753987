// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

// Material Kit 2 React components
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import opswatLogo from "assets/images/opswat_logo.png";
import katalonLogo from "assets/images/katalon_logo.png";
import gridlyLogo from "assets/images/gridly_logo.png";

function Experiences() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      id="experiences"
    >
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <MKTypography variant="h3" mb={6}>
            Experiences
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "none", xl: "block" } }}
              >
                March 2024 - Now
              </TimelineOppositeContent>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                03/2024 - Now
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="info" />
                <TimelineConnector sx={{ bgcolor: "info.main" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ marginBottom: "24px" }}>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Technical Product Manager
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  OPSWAT
                </Typography>
                <Card
                  sx={{ display: { xs: "none", lg: "flex" }, flexDirection: "row" }}
                  style={{ justifyContent: "left", alignItems: "center" }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={opswatLogo}
                    alt="opswat"
                    style={{ margin: "0px" }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "24px" }}>
                      <Typography component="div" variant="h5">
                        Technical Product Manager
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        OPSWAT
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ display: { xs: "none", xl: "flex" }, marginTop: "8px" }}
                      >
                        <Chip label="Cybersecurity" color="info" variant="outlined" size="small" />
                        <Chip
                          label="Product management"
                          color="info"
                          variant="outlined"
                          size="small"
                        />
                      </Stack>
                    </CardContent>
                  </Box>
                </Card>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "none", xl: "block" } }}
              >
                August 2022 - February 2024
              </TimelineOppositeContent>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                08/2022 - 02/2024
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="info" />
                <TimelineConnector sx={{ bgcolor: "info.main" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ marginBottom: "24px" }}>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Product Manager
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Katalon
                </Typography>
                <Card
                  sx={{ display: { xs: "none", lg: "flex" }, flexDirection: "row" }}
                  style={{ justifyContent: "right", alignItems: "center" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "24px" }}>
                      <Typography component="div" variant="h5">
                        Product Manager
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        Katalon
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ display: { xs: "none", xl: "flex" }, marginTop: "8px" }}
                      >
                        <Chip
                          label="Product management"
                          color="info"
                          variant="outlined"
                          size="small"
                        />
                        <Chip label="E2E Testing" color="info" variant="outlined" size="small" />
                      </Stack>
                    </CardContent>
                  </Box>
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={katalonLogo}
                    alt="katalon"
                    style={{ margin: "0px" }}
                  />
                </Card>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "none", xl: "block" } }}
              >
                June 2021 - July 2022
              </TimelineOppositeContent>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                06/2021 - 07/2022
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="info" />
                <TimelineConnector sx={{ bgcolor: "info.main" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ marginBottom: "24px" }}>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Product Manager
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Gridly
                </Typography>
                <Card
                  sx={{ display: { xs: "none", lg: "flex" }, flexDirection: "row" }}
                  style={{ justifyContent: "left", alignItems: "center" }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={gridlyLogo}
                    alt="gridly"
                    style={{ margin: "0px" }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "24px" }}>
                      <Typography component="div" variant="h5">
                        Product Manager
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        Gridly
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ display: { xs: "none", xl: "flex" }, marginTop: "8px" }}
                      >
                        <Chip label="SaaS" color="info" variant="outlined" size="small" />
                        <Chip
                          label="Product management"
                          color="info"
                          variant="outlined"
                          size="small"
                        />
                      </Stack>
                    </CardContent>
                  </Box>
                </Card>
              </TimelineContent>
            </TimelineItem>
            {/* <TimelineItem>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "none", xl: "block" } }}
              >
                January 2021 - September 2021
              </TimelineOppositeContent>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                01/2021 - 09/2021
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="info" />
                <TimelineConnector sx={{ bgcolor: "info.main" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ marginBottom: "24px" }}>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Business Analyst
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Gridly
                </Typography>
                <Card
                  sx={{ display: { xs: "none", lg: "flex" }, flexDirection: "row" }}
                  style={{ justifyContent: "left", alignItems: "center" }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={gridlyLogo}
                    alt="gridly"
                    style={{ margin: "0px" }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "24px" }}>
                      <Typography component="div" variant="h5">
                        Business Analyst
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        Gridly
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ display: { xs: "none", xl: "flex" }, marginTop: "8px" }}
                      >
                        <Chip
                          label="Product analysis"
                          color="info"
                          variant="outlined"
                          size="small"
                        />
                        <Chip
                          label="Machine Learning"
                          color="info"
                          variant="outlined"
                          size="small"
                        />
                      </Stack>
                    </CardContent>
                  </Box>
                </Card>
              </TimelineContent>
            </TimelineItem> */}
            <TimelineItem>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "none", xl: "block" } }}
              >
                June 2020 - May 2021
              </TimelineOppositeContent>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                06/2020 - 05/2021
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="info" />
                <TimelineConnector sx={{ bgcolor: "info.main" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ marginBottom: "24px" }}>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Data Analyst
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Gridly
                </Typography>
                <Card
                  sx={{ display: { xs: "none", lg: "flex" }, flexDirection: "row" }}
                  style={{ justifyContent: "right", alignItems: "center" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "24px" }}>
                      <Typography component="div" variant="h5">
                        Data Analyst
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        Gridly
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ display: { xs: "none", xl: "flex" }, marginTop: "8px" }}
                      >
                        <Chip
                          label="Product analysis"
                          color="info"
                          variant="outlined"
                          size="small"
                        />
                        <Chip label="Data pipeline" color="info" variant="outlined" size="small" />
                      </Stack>
                    </CardContent>
                  </Box>
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={gridlyLogo}
                    alt="gridly"
                    style={{ margin: "0px" }}
                  />
                </Card>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "none", xl: "block" } }}
              >
                February 2020 - May 2020
              </TimelineOppositeContent>
              <TimelineOppositeContent
                color="text.secondary"
                sx={{ display: { xs: "block", xl: "none" } }}
              >
                02/2020 - 05/2020
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="info" />
                <TimelineConnector sx={{ bgcolor: "info.main" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ marginBottom: "24px" }}>
                <Typography
                  variant="h5"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Quality Assurance Intern
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="span"
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  Gridly
                </Typography>
                <Card
                  sx={{ display: { xs: "none", lg: "flex" }, flexDirection: "row" }}
                  style={{ justifyContent: "left", alignItems: "center" }}
                >
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={gridlyLogo}
                    alt="gridly"
                    style={{ margin: "0px" }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "24px" }}>
                      <Typography component="div" variant="h5">
                        Quality Assurance Intern
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary" component="div">
                        Gridly
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ display: { xs: "none", xl: "flex" }, marginTop: "8px" }}
                      >
                        <Chip
                          label="Software testing"
                          color="info"
                          variant="outlined"
                          size="small"
                        />
                        <Chip label="API testing" color="info" variant="outlined" size="small" />
                      </Stack>
                    </CardContent>
                  </Box>
                </Card>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Emma Roberts"
                position={{ color: "info", label: "UI Designer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="William Pearce"
                position={{ color: "info", label: "Boss" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Ivana Flow"
                position={{ color: "info", label: "Athlete" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Marquez Garcia"
                position={{ color: "info", label: "JS Developer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Experiences;
