// react-router components
// import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function CenteredBlogCard({ image, title, description, link }) {
  return (
    <Card>
      <Link href={link} target="_self">
        <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
          <MKBox
            component="img"
            src={image}
            alt={title}
            borderRadius="lg"
            width="100%"
            position="relative"
            zIndex={1}
          />
          <MKBox
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top={0}
            sx={{
              backgroundImage: `url(${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MKBox>
        <MKBox p={3} mt={-1} textAlign="left">
          <MKTypography
            display="inline"
            variant="h5"
            textTransform="capitalize"
            fontWeight="regular"
            sx={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              overflow: "hidden",
              lineHeight: "1.5em",
              minHeight: "1.5em",
            }}
          >
            {title}
          </MKTypography>
          <MKBox mt={1} mb={3}>
            <MKTypography
              variant="body2"
              component="p"
              color="text"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "3",
                overflow: "hidden",
              }}
            >
              {description}
            </MKTypography>
          </MKBox>
        </MKBox>
      </Link>
    </Card>
  );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  // action: PropTypes.shape({
  //   type: PropTypes.oneOf(["external", "internal"]).isRequired,
  //   route: PropTypes.string.isRequired,
  //   color: PropTypes.oneOf([
  //     "primary",
  //     "secondary",
  //     "info",
  //     "success",
  //     "warning",
  //     "error",
  //     "dark",
  //     "light",
  //   ]),
  //   label: PropTypes.string.isRequired,
  // }).isRequired,
};

export default CenteredBlogCard;
