// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import ImageOnlyCard from "examples/Cards/BlogCards/ImageOnlyCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import gridly1 from "assets/images/showcases/gridly_1.png";
import gridly2 from "assets/images/showcases/gridly_2.png";
import gridly3 from "assets/images/showcases/gridly_3.png";
import gridly4 from "assets/images/showcases/gridly_4.png";
import gridly5 from "assets/images/showcases/gridly_5.png";
import gridly6 from "assets/images/showcases/gridly_6.png";

// React Slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Places() {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <MKTypography variant="h3" mb={6}>
            My working life
          </MKTypography>
        </Grid>
        <Slider {...settings}>
          <Grid container spacing={3}>
            <Grid item>
              <ImageOnlyCard
                image={gridly2}
                title=" "
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "read more",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <ImageOnlyCard
                image={gridly6}
                title=" "
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "read more",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <ImageOnlyCard
                image={gridly5}
                title=" "
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "read more",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <ImageOnlyCard
                image={gridly1}
                title=" "
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "read more",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <ImageOnlyCard
                image={gridly4}
                title=" "
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "read more",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item>
              <ImageOnlyCard
                image={gridly3}
                title=" "
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "read more",
                }}
              />
            </Grid>
          </Grid>
        </Slider>
      </Container>
    </MKBox>
  );
}

export default Places;
