// @mui material components
import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKInput from "components/MKInput";

// Material Kit 2 React examples
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MKTypography from "components/MKTypography";
import Hidden from "@mui/material/Hidden";

function FavTools() {
  const [filter, setFilter] = useState("All");
  const [open, setOpen] = React.useState(false);

  const handleClickAll = (event) => {
    const key = event.currentTarget.getAttribute("data-button-key");
    setFilter(key);
    setOpen({ [key]: open[key] });
  };

  const handleClick = (event) => {
    const key = event.currentTarget.getAttribute("data-button-key");
    setFilter(key);
    setOpen({ [key]: !open[key] });
  };
  const [data, setData] = useState([]);

  const getData = () => {
    const fetchData = async () => {
      const source = await axios(
        "https://api.airtable.com/v0/appvTftvrgQevzhNe/tbl1UZjFysyBoOyy0?maxRecords=100&view=Grid%20view&fields%5B%5D=Name&fields%5B%5D=Category&fields%5B%5D=URL&fields%5B%5D=Logo",
        {
          headers: {
            Authorization:
              "Bearer patSTbBHsfUcIXjIO.eb40c4520ee080b37ae5ef41858fe4abfadb68f5e01d43b7cd9c0b5632d1a2c6",
          },
        }
      );
      const result = source.data.records;
      setData(result);
    };
    fetchData();
  };

  // Call API & handle search function
  useEffect(() => {
    getData();
  });

  const uniqueCategory = [...new Set(data.map((item) => item.fields.Category))];

  return (
    <MKBox component="section" py={12} position="relative">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <List
              sx={{ width: "100%", maxWidth: "auto", color: "black" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <Hidden xsUp>
                <ListItemButton autoFocus divider data-button-key="All" onClick={handleClickAll}>
                  <ListItemText>
                    <MKTypography variant="4">All</MKTypography>
                  </ListItemText>
                </ListItemButton>
              </Hidden>
              {uniqueCategory.map((category) => (
                <>
                  <ListItemButton
                    divider
                    data-button-key={category}
                    key={category}
                    onClick={handleClick}
                  >
                    <ListItemText>
                      <MKTypography variant="4">{category}</MKTypography>
                    </ListItemText>
                    {open[category] ? (
                      <ExpandLess value={category} data-button-key={category} />
                    ) : (
                      <ExpandMore value={category} data-button-key={category} />
                    )}
                  </ListItemButton>
                  <Collapse in={open[category]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {data
                        .filter((item) =>
                          filter !== "All" ? item.fields.Category.includes(filter) : item
                        )
                        .map((filtereditem) => (
                          <Link
                            href={filtereditem.fields.URL}
                            underline="none"
                            target="_blank"
                            key={filtereditem.fields.Name}
                          >
                            <ListItemButton sx={{ pl: 4 }}>
                              <ListItemText>
                                <MKTypography variant="body2">
                                  {filtereditem.fields.Name}
                                </MKTypography>
                              </ListItemText>
                            </ListItemButton>
                          </Link>
                        ))}
                    </List>
                  </Collapse>
                </>
              ))}
            </List>
          </Grid>
          <Hidden xsUp>
            <Grid item xs={12} lg={9}>
              <Grid container spacing={3}>
                {data
                  .filter((item) =>
                    filter !== "All" ? item.fields.Category.includes(filter) : item
                  )
                  .map((filtereditem) => (
                    <Grid item xs={12} lg={3} py={3} key={filtereditem.id}>
                      <Link href={filtereditem.fields.URL} underline="none" target="_blank">
                        <TransparentBlogCard
                          image={filtereditem.fields.Logo[0].thumbnails.full.url}
                          title={filtereditem.fields.Name}
                          description=""
                          action={{
                            route: filtereditem.fields.URL,
                            color: "info",
                            label: "Read More",
                          }}
                        />
                      </Link>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FavTools;
