// @mui material components
import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";

// Material Kit 2 React examples
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

function Blog() {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Call API & handle search function
  useEffect(() => {
    if (searchTerm !== "") {
      const timeOutId = setTimeout(() => {
        const fetchData = async () => {
          const source = await axios(
            "https://api.airtable.com/v0/appG5dDWzeqR98l4J/Post%20Details?maxRecords=100&view=Grid%20view&sort%5B0%5D%5Bfield%5D=Created&sort%5B0%5D%5Bdirection%5D=desc&fields%5B%5D=Title&fields%5B%5D=ThumbnailURL&fields%5B%5D=Thumbnail&fields%5B%5D=Description&fields%5B%5D=Created&fields%5B%5D=CreatedBy&fields%5B%5D=ReadTime&fields%5B%5D=ReadTime&filterByFormula=(%7BDone%7D%3D'True')",
            {
              headers: {
                Authorization:
                  "Bearer patSTbBHsfUcIXjIO.eb40c4520ee080b37ae5ef41858fe4abfadb68f5e01d43b7cd9c0b5632d1a2c6",
              },
            }
          );
          const result = source.data.records.filter((source1) =>
            source1.fields.Title.toLowerCase().includes(searchTerm)
          );
          setData(result);
        };
        fetchData();
      }, 500);
      return () => clearTimeout(timeOutId);
    }
    {
      const fetchData = async () => {
        const source = await axios(
          "https://api.airtable.com/v0/appG5dDWzeqR98l4J/Post%20Details?maxRecords=100&view=Grid%20view&sort%5B0%5D%5Bfield%5D=Created&sort%5B0%5D%5Bdirection%5D=desc&fields%5B%5D=Title&fields%5B%5D=ThumbnailURL&fields%5B%5D=Thumbnail&fields%5B%5D=Description&fields%5B%5D=Created&fields%5B%5D=CreatedBy&fields%5B%5D=ReadTime&filterByFormula=(%7BDone%7D%3D'True')",
          {
            headers: {
              Authorization:
                "Bearer patSTbBHsfUcIXjIO.eb40c4520ee080b37ae5ef41858fe4abfadb68f5e01d43b7cd9c0b5632d1a2c6",
            },
          }
        );
        const result = source.data.records;
        setData(result);
      };
      fetchData();
      return () => {};
    }
  }, [searchTerm]);
  function toSlug(str) {
    /* eslint-disable no-param-reassign */
    str = str.toLowerCase();
    str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, "a");
    str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, "e");
    str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, "i");
    str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, "o");
    str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, "u");
    str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, "y");
    str = str.replace(/(đ)/g, "d");
    str = str.replace(/([^0-9a-z-\s])/g, "");
    str = str.replace(/(\s+)/g, "-");
    str = str.replace(/^-+/g, "");
    str = str.replace(/-+$/g, "");
    /* eslint-disable no-param-reassign */
    return str;
  }

  return (
    <MKBox component="section" px={{ xs: 0, lg: 12 }} py={{ xs: 2, lg: 6 }} position="relative">
      <Container>
        <Grid container spacing={3} pb={8}>
          <Grid item xs={12}>
            <MKInput
              type="email"
              label="Search here..."
              fullWidth
              value={searchTerm}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3} alignItems="center">
          {data.map((item) => (
            <Grid item xs={12} lg={4} py={4}>
              <CenteredBlogCard
                link={`/story/${item.id}/${toSlug(item.fields.Title)}/`}
                image={item.fields.Thumbnail[0].url}
                title={item.fields.Title}
                description={item.fields.Description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Blog;
