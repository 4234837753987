// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// // Material Kit 2 React components
// import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import uelLogo from "assets/images/uel_logo.png";
import rsuLogo from "assets/images/rsu_logo.png";

function Education() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      id="education"
    >
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <MKTypography variant="h3" mb={6} color="white">
            Education
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={uelLogo}
                name="University of Economics and Laws"
                position={{ color: "info", label: "" }}
                description="Bachelor’s degree, Management Information Systems, General"
                link=""
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={rsuLogo}
                name="Rangsit University"
                position={{ color: "info", label: "" }}
                description="Certification in Information Systems Project Management"
                link=""
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Education;
